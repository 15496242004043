<template>
  <div>
    <Header
      titleurl="jobwanted"
      :iscontactTel="iscontactTel"
      v-on:closeShow="closeShow"
    ></Header>
    <div>
      <div class="containers">
        <div class="rows">
          <div class="search">
            <el-input
              type="text"
              placeholder="请输入职位名称或公司"
              v-model="paramjobwant.keyword"
            ></el-input>
            <img src="../../assets/img/seach.png" />
            <el-button class="searchp" @click="jobwants">搜人才</el-button>
            <span>
              <el-button
                type="danger"
                plain
                class="recruit_position"
                @click="getToken"
                >登记简历</el-button
              >
            </span>
          </div>
        </div>
        <div class="rows">
          <router-link to="/">
            <span>
              <div class="position">牛豆豆</div>
            </span>
          </router-link>
          <span class="position_right">&gt;</span>
          <el-select
            class="searchjinyingu"
            v-model="searchjinyingu"
            @change="jobrecruit()"
            placeholder="请选择"
            style="width: 12%"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="rows">
          <div class="position">职位:</div>
          <div class="every">
            <div class="everything">
              <span
                :class="{ activeposition: activeposition == '' }"
                @click="clickposition('')"
                >全部</span
              >
              <span
                v-for="item in positions"
                :key="item.id"
                :class="{ activeposition: activeposition == item.id }"
                @click="clickposition(item)"
                >{{ item.name }}</span
              >
            </div>
          </div>
        </div>
        <div class="rows other">
          <div class="position rightlittle">更多:</div>
          <el-select
            placeholder="性别"
            v-model="paramjobwant.sexCode"
            @change="sexCodes()"
          >
            <el-option
              v-for="item in sexs"
              :key="item.itemValue"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-select
            placeholder="工作年限"
            v-model="paramjobwant.workExpCode"
            @change="workExps()"
          >
            <el-option
              v-for="item in workYears"
              :key="item.itemValue"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-select
            placeholder="学历"
            v-model="paramjobwant.educationCode"
            @change="educationCodes()"
          >
            <el-option
              v-for="item in educations"
              :key="item.itemValue"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-select
            placeholder="期望薪资"
            v-model="paramjobwant.expectSalaryCode"
            @change="expectSalaryCodes()"
          >
            <el-option
              v-for="item in expectSalarys"
              :key="item.itemValue"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <el-button @click="empty()">清空条件</el-button>
        </div>
        <div class="rows">
          <div class="position rightlittle">已选:</div>
          <el-tag
            v-for="tag in tags"
            :key="tag.name"
            closable
            @close="handleClose(tag)"
            :type="tag.type"
            >{{ tag.name }}</el-tag
          >
          <div class="allresult">
            共
            <span>{{ total }}</span
            >条结果
          </div>
        </div>
      </div>
      <div class="containers">
        <div class="rows borderdown">
          <div class="resumelist">简历</div>
          <div class="homepage">
            <span class="numbersmall" @click="numbersmall()">&lt;</span>
            <span class="redfont minimun">{{ paramjobwant.pageIndex }}</span>
            <span>/</span>
            <span class="maximum">{{ maximum }}</span>
            <span class="numberbig" @click="numberbig()">&gt;</span>
          </div>
        </div>
        <div class="rows resumebegin">
          <el-checkbox v-model="isHaveAvatar" @change="isHaveAvatars"
            >有头像</el-checkbox
          >
          <el-checkbox>有作品照片</el-checkbox>
          <el-select v-model="paramjobwant.sortRule" @change="sortRules">
            <el-option :value="0" label="智能排序"></el-option>
            <el-option :value="1" label="活跃时间"></el-option>
            <el-option :value="2" label="浏览次数"></el-option>
            <el-option :value="3" label="下载次数"></el-option>
            <el-option :value="4" label="主动投递次数"></el-option>
          </el-select>
          <img
            class="safeinvestment"
            src="../../assets/img/safeinvestment.png"
          />
        </div>
        <div class="rows resumebegin bodyheight" v-if="jobwantlist.length > 0">
          <div class="resumebody" v-for="item in jobwantlist" :key="item.id">
            <div class="imageBox">
              <img
                class="headportrait"
                v-if="item.avatarPath != ''"
                :src="item.avatarPath"
              />
              <img
                class="headportrait"
                v-else
                src="../../assets/img/timgs.jpg"
              />
            </div>
            <div class="body_font_first" @click="lookdetails(item.id)">
              <div class="turnred">
                <div class="people_name">{{ item.username }}</div>
                <span>{{ item.sex }}</span>
                <span>{{ item.age }}岁</span>
                <span>{{ item.education }}</span>
                <!-- <img src="../../assets/img/resume_iphone.png" /> -->
              </div>
              <!-- <span class="imgnumber">1图</span> -->
              <div class="expect">
                <div>
                  期望职位：
                  <span>{{ item.expectJobName }}</span>
                </div>
                <div>
                  期望地点：
                  <span>{{ item.workCity }}</span>
                </div>
              </div>
              <!-- <div class="ablabel">
                  <label>沟通力强</label>
                  <label>执行力强</label>
                  <label>学习力强</label>
                  <label>责任心强</label>
                  <label>阳光开朗</label>
                </div> -->
            </div>
            <div class="body_font_second">
              <span>两周内</span>
              <div class="expect">
                <div>
                  被浏览
                  <span class="redfont">{{ item.browsed }}</span
                  >次
                </div>
                <div>
                  被下载
                  <span class="redfont">{{ item.downloaded }}</span
                  >次
                </div>
                <div>
                  主动投递
                  <span class="redfont">{{ item.activeDelivery }}</span
                  >个职位
                </div>
              </div>
            </div>
            <div class="body_font_third">
              <span>活动动态</span>
              <div class="expect">
                <div>
                  {{
                    item.activeDate === ''
                      ? ''
                      : item.activeDate
                      ? item.activeDate.substr(0, item.activeDate.length - 9)
                      : ''
                  }}
                </div>
              </div>
            </div>
            <div class="body_font_el-button">
              <!-- <el-button type="primary" @click="reload()">下载</el-button> -->
              <el-button
                type="primary"
                @click="getDownloadcandidate(item.id, item.resumeFileName)"
                >下载</el-button
              >
            </div>
          </div>
        </div>
        <div
          class="rows resumebegin bodyheight"
          v-if="jobwantlist.length === 0"
        >
          <div class="bodyheight-nojobwant">暂无简历</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../../components/header';
import Footer from '../../components/footer';
import Utilcity from '@/utils/unilcity.js';
import {
  candidateId,
  datagridcandidate,
  downloadcandidate,
  candidatefilter,
} from '@/api/jobwant';
import Cookies from 'js-cookie';
export default {
  name: 'Jobwanted',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      positionlist: [],
      tags: [],
      options: [
        { label: '牛豆豆二手房', value: 'secondhouse' },
        { label: '牛豆豆求职', value: 'jobwanted' },
        { label: '牛豆豆招聘', value: 'recruit' },
        { label: '牛豆豆出租房', value: 'rentinghouse' },
      ],
      total: 20,
      maximum: 10,
      value6: '',
      searchjinyingu: 'jobwanted',
      param: {
        cityCode: '410100',
        key: '',
        page: 1,
        pagination: true,
        rows: 10,
      },
      cityCode: '410100',
      uid: '230',

      paramjobwant: {
        cityCode: '',
        companyId: 0,
        educationCode: '',
        expectJobs: 0,
        expectSalaryCode: '',
        isHaveAvatarId: 0,
        keyword: '',
        pageIndex: 1,
        pageSize: 10,
        sexCode: '',
        sortRule: 0,
        workExpCode: '',
      },
      isHaveAvatar: false,
      educations: [],
      expectSalarys: [],
      workYears: [],
      sexs: [],
      positions: [],
      position: '',
      jobwantlist: [],
      activeposition: '',
      iscontactTel: false,
      // token
      accessToken: '',
      // 简历id
      resumeId: 0,
      // 判断是否存在简历id
      isId: false,
    };
  },
  created() {
    this.accessToken = getCookieByName('access_token');
    this.getResumeId();
  },
  mounted() {
    Utilcity.$on('cityid', (message) => {
      this.cityCode = message;
      this.paramjobwant.cityCode = message;
      this.getdatagridcandidate();
    });
    if (localStorage.getItem('cityid')) {
      this.cityCode = localStorage.getItem('cityid');
      this.paramjobwant.cityCode = localStorage.getItem('cityid');
    }
    this.getdatagridcandidate();
    this.getcandidatefilter();
  },
  methods: {
    //搜人才
    jobwants() {
      this.paramjobwant.pageIndex = 1;
      this.getdatagridcandidate();
    },
    handleClose(tag) {
      this.tags.splice(this.tags.indexOf(tag), 1);
      if (tag.kind == 'position') {
        this.paramjobwant.expectJobs = '';
        this.activeposition = '';
      }
      if (tag.kind == 'expectSalary') {
        this.paramjobwant.expectSalaryCode = null;
      }
      if (tag.kind == 'education') {
        this.paramjobwant.educationCode = null;
      }
      if (tag.kind == 'workYear') {
        this.paramjobwant.workExpCode = null;
      }
      if (tag.kind == 'sex') {
        this.paramjobwant.sexCode = null;
      }
      this.paramjobwant.pageIndex = 1;
      this.getdatagridcandidate();
    },
    addtag(id, name, kind) {
      const self = this;
      this.tags.forEach(function (item, i) {
        if (kind == item.kind) {
          self.tags.splice(i, 1);
        }
      });
      this.tags.push({ id: id, name: name, type: 'info', kind: kind });
    },
    deltag(kind) {
      this.tags.splice(this.tags.indexOf(kind), 1);
    },
    //职位查询
    clickposition(item) {
      if (item == '') {
        this.activeposition = item;
        this.paramjobwant.expectJobs = '';
        this.deltag('position');
      } else {
        this.activeposition = item.id;
        this.addtag(item.id, item.name, 'position');
        this.paramjobwant.expectJobs = item.id;
      }
      this.paramjobwant.pageIndex = 1;
      this.getdatagridcandidate();
    },
    //期望薪资
    expectSalaryCodes() {
      const self = this;
      if (this.paramjobwant.expectSalaryCode == '') {
        this.deltag('expectSalary');
      } else {
        this.expectSalarys.forEach(function (item, i) {
          if (self.paramjobwant.expectSalaryCode == item.code) {
            self.addtag(item.code, item.name, 'expectSalary');
          }
        });
      }
      this.paramjobwant.pageIndex = 1;
      this.getdatagridcandidate();
    },
    //学历
    educationCodes() {
      const self = this;
      if (this.paramjobwant.educationCode == '') {
        this.deltag('education');
      } else {
        this.educations.forEach(function (item, i) {
          if (self.paramjobwant.educationCode == item.code) {
            self.addtag(item.code, item.name, 'education');
          }
        });
      }
      this.paramjobwant.pageIndex = 1;
      this.getdatagridcandidate();
    },
    //工作年限
    workExps() {
      const self = this;
      if (this.paramjobwant.workExpCode == '') {
        this.deltag('workYear');
      } else {
        this.workYears.forEach(function (item, i) {
          if (self.paramjobwant.workExpCode == item.code) {
            self.addtag(item.code, item.name, 'workYear');
          }
        });
      }
      this.paramjobwant.pageIndex = 1;
      this.getdatagridcandidate();
    },
    //性别
    sexCodes() {
      const self = this;
      this.sexs.forEach(function (item, i) {
        if (self.paramjobwant.sexCode == item.code) {
          self.addtag(item.code, item.name, 'sex');
        }
      });
      this.paramjobwant.pageIndex = 1;
      this.getdatagridcandidate();
    },
    //是否有头像
    isHaveAvatars() {
      if (this.isHaveAvatar == false) {
        this.paramjobwant.isHaveAvatarId = 0;
      } else {
        this.paramjobwant.isHaveAvatarId = 1;
      }
      this.paramjobwant.pageIndex = 1;
      this.getdatagridcandidate();
    },
    //智能排序
    sortRules() {
      this.paramjobwant.pageIndex = 1;
      this.getdatagridcandidate();
    },
    //求职过滤条件
    getcandidatefilter() {
      candidatefilter().then((res) => {
        let educations = res.data.result.edus;
        this.educations = educations.slice(1, educations.length - 1);
        let expectSalarys = res.data.result.sals;
        this.expectSalarys = expectSalarys.slice(1, expectSalarys.length - 1);
        let workYears = res.data.result.workExps;
        this.workYears = workYears.slice(1, workYears.length - 1);
        this.sexs = res.data.result.sexs;
        this.positions = res.data.result.job;
      });
    },
    //清空条件
    empty() {
      this.paramjobwant = {
        cityCode: '',
        companyId: 0,
        educationCode: '',
        expectJobs: 0,
        expectSalaryCode: '',
        isHaveAvatarId: 0,
        keyword: '',
        pageIndex: 1,
        pageSize: 10,
        sexCode: '',
        sortRule: 0,
        workExpCode: '',
      };
      this.tags = [];
      this.activeposition = '';
      this.getdatagridcandidate();
    },
    getdatagridcandidate() {
      const self = this;
      datagridcandidate(this.paramjobwant).then((res) => {
        this.jobwantlist = res.data.result.list;
        this.total = res.data.result.totalCount;
        this.maximum = res.data.result.totalPage;
        this.paramjobwant.pageIndex = res.data.result.currPageIndex;
      });
    },
    numbersmall() {
      if (this.paramjobwant.pageIndex === 2) {
        this.paramjobwant.pageIndex = 1;
        this.getdatagridcandidate();
      } else if (this.paramjobwant.pageIndex > 2) {
        this.paramjobwant.pageIndex--;
        this.getdatagridcandidate();
      }
    },
    numberbig() {
      if (this.paramjobwant.pageIndex === this.maximum - 1) {
        this.paramjobwant.pageIndex++;
        this.getdatagridcandidate();
      } else if (this.paramjobwant.pageIndex < this.maximum - 1) {
        this.paramjobwant.pageIndex++;
        this.getdatagridcandidate();
      }
    },
    jobrecruit() {
      this.$router.push('/' + this.searchjinyingu);
    },
    // 跳转到求职详情页
    lookdetails(id) {
      let accessToken = this.accessToken;
      if (!accessToken) {
        this.iscontactTel = true;
      } else {
        this.$router.push({
          path: '/jobwanted/resumeview',
          query: { id: id },
        });
      }
    },
    // 简历下载事件
    async getDownloadcandidate(id, FileName) {
      if (!this.accessToken) {
        this.iscontactTel = true;
      } else {
        const res = await downloadcandidate(id);
        const data = res.data;
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type:
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        );
        const link = document.createElement('a');
        link.style.display = 'none';
        link.href = url;
        link.setAttribute('download', FileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    //header头部传过来关闭登录框的操作
    closeShow(val) {
      this.iscontactTel = val;
    },
    // 获取简历id
    async getResumeId() {
      const { data: res } = await candidateId();
      this.resumeId = res.result;
    },
    // 判断是否登录，判断是否有用户简历id，有的话进入简历完善页面，没有则进入简历创建页面
    getToken() {
      let accessToken = this.accessToken;
      if (!accessToken) {
        return (this.iscontactTel = true);
      } else {
        let resumeId = this.resumeId;
        if (!resumeId) {
          this.$router.push('/jobwanted/newResume');
        } else {
          this.$router.push({
            path: '/jobwanted/newResumeperfect',
            query: { id: resumeId },
          });
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.rows .everything .activeposition,
.rows .everything .activearea,
.rows .everything .activewelfare {
  color: #ff552e;
}
.rows .everything span {
  cursor: pointer;
}
.rows .allposition {
  color: #ff552e !important;
  float: left;
}
.other .el-select {
  display: inline-block;
  width: 110px;
  margin-right: 3px;
}
>>> .other .el-select .el-input__inner,
>>> .other .el-select i {
  height: 28px;
  line-height: 28px;
}
>>> .rows .el-tag {
  height: 25px;
  display: inline-block;
  line-height: 25px;
  margin-right: 5px;
}
.containers {
  width: 1140px;
  margin: 0 auto;
  margin-bottom: 30px;
}
.rows {
  display: inline-block;
  width: 1140px;
  margin: 0 auto;
  line-height: 30px;
}
.search {
  position: relative;
  margin-left: 350px;
}
.search .el-input {
  width: 400px;
  font-weight: 100;
  position: absolute;
  top: 16px;
  font-size: 12px;
}
>>> .search .el-input .el-input__inner {
  padding: 0 40px;
  height: 35px;
  border-radius: 0%;
}
.search img {
  width: 20px;
  height: 20px;
  position: relative;
  top: 33px;
  left: 10px;
}
.search .el-button {
  background-color: #e8e8e8;
  color: #757777;
  height: 35px;
  border-radius: 0px;
  position: relative;
  left: 380px;
  top: 27.5px;
}
.containers .recruit_position {
  color: #ee5500;
  background-color: #fff6ee;
  border: 2px solid #ee5500;
  margin-left: 230px;
}
.rows .position {
  color: #666666;
  float: left;
}
.rows .position_right {
  margin: 0 5px;
}
.rows .searchjinyingu {
  display: inline-block;
}
>>> .rows .searchjinyingu .el-input__inner,
>>> .rows .searchjinyingu i {
  height: 30px;
  line-height: 30px;
}
.rows .every span {
  color: black;
  margin-right: 12px;
  float: left;
}
.rows div,
.rows span {
  display: inline;
  position: relative;
  font-size: 12px;
}

.rows .every {
  margin-left: 20px;
  padding-right: 20px;
  width: 1000px;
  float: left;
  box-sizing: border-box;
}
.rows .el-select {
  height: 22px;
  border-radius: 0px;
}
.rightlittle {
  margin-right: 20px;
}
.rows .screen {
  display: inline-block;
  height: 28px;
  margin: 0 5px;
  bottom: 1px;
  width: 150px;
}
>>> .rows .screen .el-input__inner {
  height: 28px;
  line-height: 28px;
}
.rows button {
  height: 28px;
  border-radius: 0px;
  padding: 0px 6px;
}
.rows .allresult span {
  color: #ff552e;
  margin-left: 0px;
}

.rows .resumelist {
  background-color: #2e89ff;
  color: #fff;
  font-size: 22px;
  padding: 8px 30px;
  border-radius: 3px;
}
.borderdown {
  padding-bottom: 1px;
  border-bottom: 2px solid #2e89ff;
}
.rows .homepage {
  float: right;
}
.redfont {
  color: #ff552e !important;
}
.rows .homepage span {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16px;
  color: #ab9e9e;
}
.rows .homepage .numbersmall,
.rows .homepage .numberbig {
  cursor: pointer;
  font-size: 20px;
}
.bodyheight {
  min-height: 300px;
}
.bodyheight .bodyheight-nojobwant {
  font-size: 14px;
  line-height: 60px;
  left: 45%;
  color: #909399;
}
.resumebegin {
  padding: 20px 0 10px 20px;
  position: relative;
}
.resumebegin .checkbox {
  width: 15px;
  height: 15px;
  float: left;
}
.resumebegin .checkbox + label {
  margin-right: 20px;
}
.safeinvestment {
  position: absolute;
  z-index: 10;
  right: 0;
  top: 40px;
  max-width: 200px;
  max-height: 200px;
}
.resumebegin label {
  padding-left: 5px;
  float: left;
  font-size: 12px;
  line-height: 25px;
  font-weight: 500;
}
.resumebegin > .el-button {
  font-size: 12px;
  margin-right: 20px;
  float: left;
}
.resumebegin .el-select {
  width: 150px;
  margin-right: 200px;
  float: right;
}
>>> .resumebegin .el-select .el-input__inner {
  width: 150px;
  height: 30px;
  line-height: 30px;
}
>>> .resumebegin .el-select i {
  line-height: 30px;
}
.resumebegin .resumebody {
  display: block;
  height: 150px;
  margin-top: 30px;
  border-bottom: 1px solid #edeef0;
}
.resumebegin .resumebody .checkresume {
  margin: 40px 20px 0 0;
}
.resumebegin .resumebody .headportrait {
  width: 100px;
  height: 100px;
  float: left;
  border-radius: 50%;
}
.resumebegin .resumebody .body_font_first {
  width: 430px;
  margin-left: 20px;
  float: left;
}
.resumebegin .resumebody .body_font_first .turnred div:hover,
.resumebegin .resumebody .body_font_first .turnred span:hover {
  color: #ff552e;
}

.resumebegin .resumebody .body_font_first .people_name {
  font-size: 24px;
  margin-right: 8px;
  cursor: pointer;
}
.resumebegin .resumebody .body_font_first span {
  color: black;
  font-size: 17px;
  cursor: pointer;
  margin: 0 8px;
  font-weight: 500;
}
.resumebegin .resumebody .body_font_first img {
  width: 15px;
  height: 17px;
  cursor: pointer;
  margin-bottom: 7px;
}
.resumebegin .resumebody .body_font_first .imgnumber {
  color: #60cbdd;
  font-size: 12px;
  background-color: #eaf7f8;
}
.resumebegin .resumebody .expect div {
  display: block;
  cursor: pointer;
  font-size: 14px;
  color: #999999;
}
.resumebegin .resumebody .expect span {
  display: inline-block;
  font-size: 14px;
  color: #999999;
  max-width: 300px;
  vertical-align: top;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.resumebegin .resumebody .body_font_second {
  width: 150px;
  float: left;
  border-right: 1px solid #eeeeee;
}
.resumebegin .resumebody .body_font_third {
  margin-left: 20px;
  width: 100px;
  float: left;
}
.resumebegin .resumebody .body_font_el-button {
  float: left;
}
.resumebegin .resumebody .body_font_el-button .el-button {
  margin-top: 30px;
  height: 30px;
  padding: 2px 15px;
  border: 0px;
  border-radius: 3px;
}
.resumebegin .resumebody .ablabel label {
  color: #5babf2;
  margin-right: 5px;
  height: 25px;
  padding: 0px 5px;
  border-radius: 3px;
  border: 1px solid #5babf2;
  max-width: 90px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
